
import { Injectable, EventEmitter } from '@angular/core';
import { catchError, Observable, of, tap, throwError,take,map, EMPTY, shareReplay } from 'rxjs';
import { FavoriteChart } from 'src/app/models/favoriteChart';
import { ChartFolder } from 'src/app/models/chartFolder';
import { indexCalculatorInput } from 'src/app/models/indexCalculatorInput';
import { Contact } from 'src/app/models/contact';
import { Glossary } from 'src/app/models/glossary';
import { QuoteIfa } from 'src/app/models/quote-ifa';
import { HttpClient } from '@angular/common/http';


type HtmlSnippets = {  
  index_fund_definition_sec_traditional$: () => Observable<any>;
  index_fund_definition_sec_non_traditional$: () => Observable<any>;  
}; 
@Injectable({
  providedIn: 'root'
})

export class Data2Service {
  //formToken is like a session variable. The server will validate the token on submission
  //declarative
 
  REFRESH_INTERVAL = 1000*60*60; //1hr. this value is in ms
  
  UserEmitter:EventEmitter<any> = new EventEmitter<any>();

  constructor(private http:HttpClient) { }

  
  returnsMonthly(query: string) {
    return this.http.get(`https://data2.ifa.com/odata/v1/returns/monthly/${query}`).pipe(
      catchError(err=>{return of("error returnsMonthly",err)})
  );
  }

  articlesCounts$(sitegroup:string='all'):Observable<any>{
    return this.http.get(`https://data2.ifa.com/odata/v1/articles/counts?sitegroup=${sitegroup}`).pipe(
      catchError(err=>{return of("error articlesCounts",err)})
  );
  }

  //decalrative
  articleVideoWhitelistCheck$(friendlyURL:string):Observable<boolean>{
    return this.http.get(`https://data2.ifa.com/odata/v1/articles/${friendlyURL}/whitelist`)
    .pipe(
      //tap((data:any)=>console.log("portfolio_underlying_mutual_funds$ tap",JSON.stringify(data))),
      map((resp)=>{   
        if(resp==true){
          //console.log(`${friendlyURL} is whitelisted`);
          return true;
        }       
        else{
          //console.log(`${friendlyURL} is NOT whitelisted`);
          return false;
        }
      }),
      //tap((mappedResp:any)=> console.log(mappedResp)),
      catchError(err => {
        console.log("There was an error getting 'Article / Video Whitelist' from the backend server. "+friendlyURL); 
        return of(false);
      }),
      shareReplay(120000) //2 min.
    );
  }

  becomeClientFormSubmit$(body:any):Observable<any>{
    return this.http.post(`https://data2.ifa.com/api/v1/form/become-client`,body).pipe(
      catchError(err=>{return of("error posting become-client",err)})
  );
  }

  FindAnAdvisorFormSubmit(body:any):Observable<any>
  {
    var url = "https://data2.ifa.com/api/v1/form/findanadvisor";
    // var url = "https://localhost:44396/api/v1/form/findanadvisor";
    return this.http.post(url, body)
      .pipe
      (
        catchError(err=>{return err}) // of("Bad",err)})
      // )
    );
  }

  EventRegistration_Add(body:any):Observable<any>
  {
    var url = "https://data2.ifa.com/api/v1/form/EventRegistration_Add";  
    // var url = "https://localhost:44396/api/v1/form/EventRegistration_Add";
    return this.http.post(url, body)
      .pipe
      (
        catchError(err=>{console.log(err); console.log('--body--'); console.log(body); return err}) // of("Bad",err)})
      );
  }
  
  consentLog$(body:any):Observable<any>{
    return this.http.post(`https://data2.ifa.com/api/v1/consent-log`,body).pipe(     
      //catchError(()=>of("error in contact options")),     
    );
  }

  //contact us form contacts
  //declarative
  contacts$(){ 
    return this.http.get<Contact[]>(`https://data2.ifa.com/odata/v1/contact?$select=name,dropdown`).pipe(     
      //catchError(()=>of("error in contact options")),
      shareReplay(this.REFRESH_INTERVAL)      
    );
  }
    
  contactUsFormSubmit$(body:any):Observable<any>{
    return this.http.post(`https://data2.ifa.com/api/v1/form/contact`,body).pipe(
      catchError(err=>{return of("error posting contact us",err)})
  );
  }

  galtonboardContactUsFormSubmit$(body:any):Observable<any>{
    var apiurl = "https://data2.ifa.com/api/v1/form/galtonboardcontact"; 
    return this.http.post(apiurl, body).pipe(
      catchError(err=>{return of("error posting contact us",err)})
  );
  }

  //formToken is like a session variable. The server will validate the token on submission
  //declarative
  formToken$(){ 
      return  this.http.get<any>(`https://data2.ifa.com/api/v1/form/token`).pipe(
        catchError(err=>{return of("error getting form token")})
    );
  }

  //declarative
  ifaOptimizedTargetDatesComparisonData$():Observable<any>{
    return this.http.get(`https://data2.ifa.com/api/v1/ifa-optimized-target-dates/comparison-data/`).pipe(
      map(x=>{
        //console.log("ifaOptimizedTargetDatesComparisonData$ > ",x)
        return x;

      }),
     catchError(
        err=>of("error getting ifaOptimizedTargetDateUnderlyingFundIds",err)
      )
    )
  }

  //declarative 
  investment$(query:string=""):Observable<any>{
    return this.http.get(`https://data2.ifa.com/odata/v1/investment${query}`).pipe(catchError(err=>of([])))
  }

  investmentPerformanceDailyIndexGRR$(ids:string="",startDates:string,endDates:string,startingDollar:number=1,showIndexValues:boolean=true):Observable<any>{
    return this.http.get(`https://data2.ifa.com/api/v1/investment/performance/daily/index/grr?ids=${ids}&startDates=${startDates}&endDates=${endDates}&startingDollar=${startingDollar}&showIndexValues=${showIndexValues}`).pipe(catchError(err=>of([])))
  }

  openAccountFormSubmit$(body:any):Observable<any>{
    return this.http.post(`https://data2.ifa.com/api/v1/form/open-account`,body).pipe(
      catchError(err=>{return of("error posting open-account",err)})
  );
  }

  petitionCount$():Observable<any>{
    return this.http.get(`https://data2.ifa.com/api/v1/petition/count?id=5cc9f26f-c380-49ac-a888-c37542482228`).pipe(
      catchError(err=>{return of("error getting petition count",err)})
  );
  }
  petitionFormSubmit$(body:any):Observable<any>{
    return this.http.post(`https://data2.ifa.com/api/v1/petition/?id=5cc9f26f-c380-49ac-a888-c37542482228`,body).pipe(
      catchError(err=>{return of("error posting petition",err)})
  );
  }
  petitionTokenGet$():Observable<any>{
    return this.http.get(`https://data2.ifa.com/api/v1/petition/token`).pipe(
      catchError(err=>{return of("error posting petition token",err)})
  );
  }

  planSponsorChecklistFormSubmit$(body:any):Observable<any>{
    return this.http.post(`https://data2.ifa.com/api/v1/form/plan-sponsor-checklist`,body).pipe(
      catchError(err=>{return of("error posting plan-sponsor-checklist",err)})
  );
  }

  //declarative
  portfolio_underlying_mutual_funds$(portfolio:string):Observable<any>{
    return this.http.get(`https://data2.ifa.com/odata/v1/portfolio-underlying-mutual-funds?portfolio=${portfolio}`)
    .pipe(
      //tap((data:any)=>console.log("portfolio_underlying_mutual_funds$ tap",JSON.stringify(data))),
      map((resp:any)=>{  
        resp.data =  resp.data.map((x:any)=>{         
          x.specific_asset_class=x.general_asset_class_name?.replace(/[_]/g," ");   
          if(x.specific_asset_class){  
            x.general_asset_class_name = x.specific_asset_class
            .replace(/US (Large Cap|Large Value)/g,"US Large")
            .replace(/US (Mid Cap Blend|Mid Cap|Mid Value)/g,"US Mid")
            .replace(/US (Small Cap|Small Value|Small Blend)/g,"US Small")
            .replace(/Global Real Estate/g,"Real Estate")
            .replace(/International (Blend|Small Cap Value|Small Cap|Small|Value|Sustainability Core|Social Core)/g,"International")
            .replace(/Emerging Markets (Blend|Small Cap|Small|Value|Sustainability Core|Social Core)/g,"Emerging Markets")
            .replace(/(One Year|Two Year|Five Year|Short Term Gov|Short Term|Investment Grade|Global Core|Sustainable|Social|Tax Exempt) Fixed Income/g,"Fixed Income")
          ;
          }   
          return x;
        });
        //console.log("resp.data", resp.data)
        return resp;
      }),
      //tap((mappedResp:any)=> console.log(mappedResp)),
      catchError(err => of("There was an error getting 'Implementations' from the backend server.",portfolio,err)),
      shareReplay(this.REFRESH_INTERVAL)
    );
  }
  
    //declarative
  index_calculator(indexCalculatorInput:indexCalculatorInput):Observable<any> { 
     return this.http.post<any>("https://data2.ifa.com/api/v1/calculators/index-calculator/",indexCalculatorInput)
    .pipe(
      //tap(data=>console.log("dub_get$ tap",JSON.stringify(data))),
      catchError(err => of("There was an error getting 'IFA Index Calculator Results' from the backend server.",indexCalculatorInput))
    );
  }

  public_get(){
    return this.http.get<any>("https://data2.ifa.com/public");
  }

  ///Interceptor will add auth header (MSALInterceptorConfigFactory)
  protected_get(){
    return this.http.get<any>("https://data2.ifa.com/protected");
  }

  ///Interceptor will add auth header (MSALInterceptorConfigFactory)
  dup_get(){     
      return this.http.get<any>("https://data2.ifa.com/api/v1/dup").pipe(
        shareReplay(2000)
      );       
  }

  fullyConsent$ = new Observable<boolean>();

  //declarative
  dup_get$(){ 
    return this.http.get<any>("https://data2.ifa.com/api/v1/dup")
    .pipe(
      //tap(data=>console.log("dub_get$ tap",JSON.stringify(data))),    
      catchError(err => of("There was an error getting user properties from the backend server.")),
      shareReplay(2000)
    );
  }

  dup_get_consent$(){ 
    return this.http.get<any>("https://data2.ifa.com/api/v1/dup").pipe(
      //tap(data=>console.log("dup_get_consent$ data",data)),   //DEBUG USING TAP
      map(resp=>{
        for(var i=0;i<resp.length;i++){
          resp[i].parts = resp[i].value.split(/[;|]+/);
        }
            console.log("dup_get_consent$",resp);
            console.log("dup_get_consent$",resp[0]);
            // console.log("dup_get_consent$",resp);
            // resp.parts = resp.value.split(/[;|]+/);
            // console.log("dup_get_consent$ (split)",resp);
            var readAndUnderstoodIFAsBacktestingDisclosuresAndIndexDescriptions = resp.find((x: { parts: string[]; })=>x.parts[0]=="readAndUnderstoodIFAsBacktestingDisclosuresAndIndexDescriptions");                
            var fullyConsented = readAndUnderstoodIFAsBacktestingDisclosuresAndIndexDescriptions?.parts[1]=="Yes";
            console.log("fullyConsented?",fullyConsented, [],  readAndUnderstoodIFAsBacktestingDisclosuresAndIndexDescriptions?.parts[1]=="Yes")
            
            if(fullyConsented){
              return {fullyConsented:true,consents:[readAndUnderstoodIFAsBacktestingDisclosuresAndIndexDescriptions]};
            }
            else{
              return {fullyConsented:false,consents:[readAndUnderstoodIFAsBacktestingDisclosuresAndIndexDescriptions]};
            }          
      }),
      shareReplay(2000)
    ); 
  }

  ///
  ///Interceptor will add auth header (MSALInterceptorConfigFactory)
  ///dup_post(kv = delimited by '|')
  ///
  dup_post(kv:string){   
    console.log(Date.now+" - pushing to server:",kv);
     return this.http.post<any>("https://data2.ifa.com/api/v1/dup", {
        value:kv
      }).pipe(tap(x=>
        console.log(Date.now+" - pushed to server:",kv, x)
        ));
    }

  emailSubscribe(email:string):Observable<any>{
    return this.http.post(`https://data2.ifa.com/api/v1/email/newslettersubscribe`,{email:email})   
  .pipe(
    //tap(data=>console.log("dub_get$ tap",JSON.stringify(data))),    
    catchError(err => of("There was an error subscribing to the newsletter."))
  );

  }

    //declarative
  html_snippets_ifa_disclosure$(){ 
    return this.http.get<any>("https://data2.ifa.com/odata/v1/html_snippets/?$filter=description eq 'IFA Disclosures'")
    .pipe(
      //tap(data=>console.log("dub_get$ tap",JSON.stringify(data))),       
      catchError(err => of("There was an error getting 'IFA Disclosures' the backend server."))
    );
  }

 

  htmlSnippets:HtmlSnippets = 
    {

      index_fund_definition_sec_non_traditional$: ()=>{
        return this.http.get<any>("https://data2.ifa.com/odata/v1/html_snippets/?$filter=description eq 'index-fund-definition-sec-non-traditional'")
        .pipe(
          //tap(data=>console.log("dub_get$ tap",JSON.stringify(data))),    
          catchError(err => of("There was an error getting 'index-fund-definition-sec-non-traditional' the backend server."))
        )
      }
      ,
      index_fund_definition_sec_traditional$: ()=>{ 
        return this.http.get<any>("https://data2.ifa.com/odata/v1/html_snippets/?$filter=description eq 'index-fund-definition-sec-traditional'")
          .pipe(
            //tap(data=>console.log("dub_get$ tap",JSON.stringify(data))),    
            catchError(err => of("There was an error getting 'index-fund-definition-sec-traditional' the backend server."))
          );    
      }
    }
  
    // getBooksNThumbnails$ = this.http.get<any[]>("https://localhost:44387/api/v1/books")
    //   .pipe
    //     (
    //       catchError(() => { return [];}),
    //       map((response: any[]) => {return response }),
    //       shareReplay(this.REFRESH_INTERVAL)
    //     );

  // testPipe(vcid: number = 0)
  chartview_gettotal$(vcid: number=0): Observable<any>
  { 
   var url = `https://data2.ifa.com/odata/v1/favoritecharts/gettotalviews?vcid=${vcid}`;
   return this.http.get<any[]>(url)
    .pipe
    (
      catchError((e) =>
      {
        return EMPTY;
      }),
      map((x: any[])=>
      { 
        var data = x;
        return data;
      })
    )
  }
  
  chartview_postincrement$(body: any): Observable<any>
  {
    var url = "https://data2.ifa.com/odata/v1/favoritecharts/viewincrement";
    return this.http.post<any>(url, body)
      .pipe
      (
        catchError((e) => { return EMPTY; }),
        map(() => {return EMPTY})
      )
  }

  //declarative
  favorite_charts$(){
    return this.http.get<FavoriteChart[]>("https://data2.ifa.com/odata/v1/favoriteCharts/v2")
    .pipe(
      //tap(data=>console.log("favorite_charts$ tap",JSON.stringify(data))),    
      catchError((e) => {
        console.error("There was an error getting 'Favorite Charts' the backend server."); 
      
        return EMPTY;
      }
        ),
      map((x:FavoriteChart[])=>{
        //console.log("data2service",x)
        var categories: ChartFolder[] = [];
        var catIndex = -1;
        for(var i=0;i<x.length;i++){
          if(catIndex==-1 || categories[catIndex].name != x[i].category){
            var cf =new ChartFolder();
            cf.name=x[i].category;
            categories.push(cf);
            catIndex++;         
          }
          x[i].index = i+1;
          x[i].id = Number.parseInt(x[i].imageUrl.split("/")[5]);        
        // https://data.ifa.com/content/charts/308/thumbnail_177w_100h_.png"

        // x[i].imageUrl="https://data.ifa.com/content/charts/154/thumbnail_177w_100h_.png?v=1"
          categories[catIndex].charts.push(x[i]);
          
        }
        return categories;
      }),
      shareReplay(this.REFRESH_INTERVAL)
    );
  }


 
  people$(query:string=""){   
    return this.http.get<any>(`https://data2.ifa.com/api/v1/people${query}`);
   }

   relatedContentByTags(tags:string=""){
    return this.http.get<any>(`https://data2.ifa.com/api/v1/tags?tags=${tags}`)
    .pipe(
      catchError(err=>{
          console.log(`Error getting related content for tags: ${tags}`);
          return of([]);
        }
      )
    );
   }

   taxEstimates(){
    return this.http.get<any>(`https://data2.ifa.com/api/v1/tax-estimates`);
   }

   videosByCategory$(query:string=""){
     return this.http.get<any>(`https://data2.ifa.com/odata/v1/videos/categories_v2${query}`);
   }

   
  videosByCategory_SP(query:string="")
  {
    return this.http.get<any>('https://data2.ifa.com/odata/v1/videos/sp');
  }

  videosFavorites$(query:string=""){
    return this.http.get<any>(`https://data2.ifa.com/odata/v1/videos/favorites${query}`);
  }
  videos$(query:string=""){
    return this.http.get<any>(`https://data2.ifa.com/odata/v1/videos/${query}`);
  }

   glossaryTermsOfArticle$(friendlyURL:string){
    //searches for terms in the content, title, keywords, description and teaser. returns alphabetically sorted array of glossary items
    return this.http.get<Glossary[]>(`https://data2.ifa.com/odata/v1/articles/${friendlyURL}/terms`);
  }
  relatedQuotesByTags$(tags:string){
    //comma seperated tags, searches for related quotes
    return this.http.get<QuoteIfa[]>(`https://data2.ifa.com/api/v1/tags/quotes/?tags=${tags}`).pipe(tap(x=>console.log("relatedQuotesByTags",x)));
  }

  
  chartFolders(){
    var url = 'https://data2.ifa.com/odata/v1/chart-folders'
    return this.http.get<any>(url);
  }
  chartsInFolders(){
    var url = 'https://data2.ifa.com/odata/v1/charts-in-folders?$expand=cmS_HTML5_Chart'
    return this.http.get<any>(url);
  }
  
}
